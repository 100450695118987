.slick-next:before,
.slick-prev:before {
  display: none;
}
.slick-slide img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.slick-track {
  display: flex;
}

.slick-initialized .slick-slide {
  overflow: hidden;
  width: 100% !important;
  cursor: pointer;
}
.slick-initialized .slick-slide img {
  border-radius: 0;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ff1212;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  color: #ff1212;
}
.slick-dots li,
.slick-dots li button {
  width: 10px;
  height: 10px;
  margin: 0 2px;
}

 